import React, { useState, useEffect, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { message, Input, Dropdown, Modal, Button } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import {
  PlusOutlined,
  MinusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useApi } from "../../Api/useApi.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeFormList } from "../../Redux/slices/formSlice.tsx";
import { useUser } from "@clerk/clerk-react";

const Workspaces = ({
  workSpaces,
  setWorkSpaces,
  selectedWorkSpace,
  setSelectedWorkSpace,
  isCollapsed,
  setIsCollapsed,
}) => {
  const api = useApi();
  const { user } = useUser();
  const dispatch = useDispatch();
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceNameUpdate, setWorkspaceNameUpdate] = useState(
    workspaceName || ""
  );

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const hasCreatedDefaultRef = useRef(false);

  /* workspace handle */
  /* handle create */
  const [messageApi, contextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: (data) => api.workspaces.createWorkspace(data),
    onSuccess: (data) => {
      messageApi.success("workspace created successfully");
      setIsCollapsed(true);
      queryClient.invalidateQueries({ queryKey: ["workspaceList"] });
      setSelectedWorkSpace(data?.data?._id);
      sessionStorage.setItem("selectedWorkSpace", data?.data?._id);
    },
    onError: (error) => {
      if (error.response) {
        messageApi.error(` ${error.response.data.detail}`);
      } else {
        messageApi.error(
          "Could not create workspace: An unexpected error occurred"
        );
      }
      console.error("Error details:", error);
    },
  });

  const createWorkspace = () => {
    if (workspaceName.trim() === "") {
      messageApi.error("Workspace name is required!");
      return;
    }
    const workspceObj = {
      name: workspaceName,
    };
    createMutation.mutate(workspceObj);

    setWorkspaceName("");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createWorkspace();
    }
  };

  /* handle onBording */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isOnboarding = queryParams.get("onboarding") === "true";

    if (isOnboarding && !hasCreatedDefaultRef.current) {
      const name = `${user?.firstName}'s workspace`;
      const defaultName = "default workspace";
      const workspaceObj = {
        name: `${user?.firstName ? name : defaultName}`,
      };
      createMutation.mutate(workspaceObj);
      hasCreatedDefaultRef.current = true;
      queryParams.delete("onboarding");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.search, navigate, workSpaces]);

  /* handle fetch */

  const {
    data: workspaceData,
    isSuccess: isSuccessWorkspace,
    isFetching: isFetchingWorkspaces,
  } = useQuery({
    queryKey: ["workspaceList"],
    queryFn: () => api.workspaces.getWorkspaces(),
    staleTime: Infinity,
  });

  useEffect(() => {
    const sessionWorkspace = sessionStorage.getItem("selectedWorkSpace");
    if (sessionWorkspace && workspaceData?.data) {
      setWorkSpaces(workspaceData?.data?.workspaces);
      setSelectedWorkSpace(sessionWorkspace);
    } else if (isSuccessWorkspace && workspaceData?.data) {
      setWorkSpaces(workspaceData?.data?.workspaces);
      const defalut = workspaceData?.data?.workspaces?.[0]?._id;
      setSelectedWorkSpace(defalut);
      sessionStorage.setItem("selectedWorkSpace", defalut);
    }
  }, [isSuccessWorkspace, workspaceData]);

  /* handle update */
  const updateMutation = useMutation({
    mutationFn: (data) =>
      api.workspaces.updateWorkspace(selectedWorkSpace, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workspaceList"] });
      setIsModalOpen(false);
      setSelectedWorkSpace(selectedWorkSpace);
      messageApi.success("workspace updated successfully");
    },
    onError: (error) => {
      messageApi.error("Could not update workspace");
    },
  });

  const updateWorkspace = () => {
    const workspceObj = {
      name: workspaceNameUpdate,
    };
    updateMutation.mutate(workspceObj);
  };

  /* handleDelete */
  /* handleDelete */
  const deleteMutation = useMutation({
    mutationFn: (workspace_id) => api.workspaces.deleteWorkspace(workspace_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workspaceList"] });

      messageApi.success("workspace deleted successfully");
    },
    onError: (error) => {
      messageApi.error("Could not delete workspace");
    },
  });

  /* modal states */
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleOkDelete = () => {
    setIsDeleteModalOpen(false);
  };
  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteWorkspace = (workspace_id) => {
    sessionStorage.removeItem("selectedWorkSpace", workspace_id);
    dispatch(removeFormList());
    deleteMutation.mutate(workspace_id);
    setIsDeleteModalOpen(false);
  };

  const handleworkspaceId = (id) => {
    setSelectedWorkSpace(id);
    sessionStorage.setItem("selectedWorkSpace", id);
  };
  return (
    <>
      <ul className="flex flex-col pb-4 mt-6">
        {contextHolder}
        <div className="flex items-center justify-between mb-1 ">
          <p className="text-xs text-start opacity-30 mb-1.5 font-medium">
            Workspace
          </p>
          <button onClick={toggleCollapse}>
            {isCollapsed ? (
              <PlusOutlined className="text-[#B2B2B2] font-bold h-[2px] w-3"></PlusOutlined>
            ) : (
              <MinusOutlined className="text-[#B2B2B2] font-bold h-[2px] w-3"></MinusOutlined>
            )}
          </button>
        </div>
        <AnimatePresence initial={false}>
          {!isCollapsed && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mb-2"
            >
              <Input
                classNames={{
                  input: "rounded-lg mx-0",
                }}
                placeholder="Untitled Workspace"
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
                onKeyDown={handleKeyDown}
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-slot="icon"
                    className="h-3.5 w-3.5"
                  >
                    <path d="M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z"></path>
                  </svg>
                }
                suffix={
                  <span className="text-[#B2B2B2] items-center"> ↵ </span>
                }
                className="border-1 border-dashed border-[#B2B2B2]"
              />
            </motion.div>
          )}
        </AnimatePresence>
        {!isFetchingWorkspaces ? (
          workSpaces.length >= 1 && !isFetchingWorkspaces ? (
            workSpaces.map((space) => (
              <button
                className={`group py-0.5 my-1 flex text-zinc-950  group  items-center rounded-lg px-2.5 text-[0.8rem] justify-between  ${
                  selectedWorkSpace === space._id
                    ? "bg-zinc-100  font-bold"
                    : "bg-zinc-50"
                } `}
                key={space?._id}
                onClick={() => {
                  handleworkspaceId(space?._id);
                }}
              >
                <div
                  className={`text-zinc-950  group flex items-center justify-between rounded-lg px-2.5 py-2 text-[0.8rem]
          ${
            selectedWorkSpace === space._id
              ? "bg-zinc-100  font-bold"
              : "bg-zinc-50"
          } `}
                >
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      data-slot="icon"
                      className="h-3.5 w-3.5"
                    >
                      <path d="M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z"></path>
                    </svg>
                    <span>{space?.name}</span>
                  </div>
                </div>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <button
                            className="flex items-center gap-1"
                            onClick={() => {
                              showModal();
                              setWorkspaceNameUpdate(space?.name);
                            }}
                          >
                            <EditOutlined /> Rename
                          </button>
                        ),
                        key: "1",
                      },
                      {
                        label: (
                          <button
                            className="flex items-center gap-1 text-red-500"
                            onClick={() => {
                              showDeleteModal();
                            }}
                          >
                            <DeleteOutlined /> Delete
                          </button>
                        ),
                        key: "2",
                      },
                    ],
                  }}
                  trigger={["click"]}
                  className="hover:cursor-pointer"
                >
                  <a onClick={(e) => e.preventDefault()} key={space?._id}>
                    <div
                      type="button"
                      aria-haspopup="dialog"
                      aria-expanded="false"
                      aria-controls="radix-:rn:"
                      data-state="closed"
                      className="h-7 w-7 z-[300] relative rounded-lg overflow-hidden flex items-center justify-center"
                    >
                      <div className="absolute top-0 left-0 w-full h-full transition duration-300 opacity-0 hover:opacity-20"></div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        data-slot="icon"
                        className="relative z-50 w-4 h-4 pointer-events-none"
                      >
                        <path d="M3 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM8.5 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM15.5 8.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"></path>
                      </svg>
                    </div>
                  </a>
                </Dropdown>
              </button>
            ))
          ) : (
            <div className="text-sm font-medium ">No workspaces to show</div>
          )
        ) : (
          <>
            <div className="py-0.5 my-1 flex group shadow  items-center rounded-lg px-2.5 animate-pulse-fast h-[35px] bg-gray-200 opacity-50"></div>
            <div className="py-0.5 my-1 flex group shadow  items-center rounded-lg px-2.5 animate-pulse-fast h-[35px] bg-gray-200 opacity-50"></div>
            <div className="py-0.5 my-1 flex group shadow  items-center rounded-lg px-2.5 animate-pulse-fast h-[35px] bg-gray-200 opacity-50"></div>
          </>
        )}
      </ul>
      <Modal
        title={
          <div className="flex flex-col ">
            <h1 className="flex items-center mb-1 text-xl font-medium tracking-tight">
              Rename Workspace
            </h1>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        closeIcon={null}
        width={400}
      >
        <main className="flex flex-col gap-4 ">
          <span className="mt-4 text-sm text-zinc-500">Workspace Name</span>
          <Input
            placeholder="Form Name"
            className="mx-0 mb-4 rounded-lg shadow-md"
            value={workspaceNameUpdate}
            defaultValue={workspaceNameUpdate}
            onChange={(e) => setWorkspaceNameUpdate(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateWorkspace();
              }
            }}
          />

          <div className="flex justify-end gap-2 mt-auto ">
            <button
              className="  flex items-center bg-white  hover:bg-[#F4F4F5] rounded-lg py-2 px-2 text-black font-medium"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="flex items-center gap-2 px-2 py-2 font-medium text-white bg-black rounded-lg hover:bg-zinc-800"
              onClick={() => {
                updateWorkspace();
              }}
            >
              Rename
            </button>
          </div>
        </main>
      </Modal>
      <Modal
        title={
          <div className="flex flex-col justify-center ">
            <h1 className="flex items-center justify-center mb-1 text-xl font-medium tracking-tight text-center">
              Delete Workspace
            </h1>
            <p className="text-sm font-normal text-center text-zinc-500">
              This action will delete all forms in the workspace. Do you want to
              proceed?
            </p>
          </div>
        }
        open={isDeleteModalOpen}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        footer={[]}
        closeIcon={null}
        width={350}
      >
        <main className="flex flex-col gap-4 mt-5 ">
          <div className="flex justify-center gap-2 mt-auto ">
            <Button
              type="text"
              onClick={() => {
                handleCancelDelete();
              }}
            >
              Cancel
            </Button>

            <Button
              danger
              onClick={() => {
                deleteWorkspace(selectedWorkSpace);
              }}
            >
              Delete
            </Button>
          </div>
        </main>
      </Modal>
    </>
  );
};

export default Workspaces;
